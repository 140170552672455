/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { Components, Level } from '@powdr/constants';
import { getBackgroundImageSrc, isEmpty, textFilter } from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const DynamicSEO = ({
  title,
  url,
  href,
  currentPage,
  levels,
  pages,
  defaultSeason,
  dynamic,
}) => {
  const { pathname } = useLocation();
  const hotjarID = process.env.GATSBY_HOTJAR_ID || null;
  const hotjarSV = process.env.GATSBY_HOTJAR_SV || null;
  const {
    metaImageFallback,
    defaultMeta: {
      metaImage: defaultMetaImage,
      metaDescription: defaultMetaDescription,
      metaTitle: defaultMetaTitle,
    },
  } = useStaticData();
  const metaNoFollow = useMemo(() => (isEmpty(dynamic)
    ? currentPage?.isNoFollow
    : !!(dynamic?.field_nofollow)), [dynamic, currentPage]);
  const metaNoIndex = useMemo(
    () => (isEmpty(dynamic)
      ? currentPage?.isNoIndex
      : !!(dynamic?.field_noindex)),
    [dynamic, currentPage],
  );
  const metaTitle = useMemo(
    () => (
      (dynamic)
        ? dynamic?.field_meta_page_title || dynamic.title || defaultMetaTitle
        : currentPage?.metaPageTitle || title || defaultMetaTitle
    ),
    [currentPage, title, dynamic, defaultMetaTitle],
  );
  const metaUrl = url || '';
  const metaOpengraphHref = href || '';
  const metaDescription = useMemo(
    () => ((dynamic)
      ? dynamic?.field_meta_page_description || dynamic?.field_meta_description || dynamic.field_short_description || defaultMetaDescription
      : currentPage?.metaPageDescription || currentPage?.pageDescription || defaultMetaDescription
    ),
    [currentPage, dynamic, defaultMetaDescription],
  );
  const metaImage = useMemo(() => {
    if (!pages && !defaultMetaImage && !metaImageFallback) return '';
    // eslint-disable-next-line max-len
    const { gatsbyImage: pageImageSrc } = currentPage?.relationships?.metaPageImage?.relationships?.image || {};

    const getHeroImageFromContent = (content) => {
      const findComponent = (key) => content.filter(
        (component) => (
          component.typeName === key
          && (
            component?.componentSeason === defaultSeason
            || component?.componentSeason === null
          )
        ),
      ) || [];
      const hero = findComponent(Components.CONTENT_BLOCK_HERO)?.[0];

      // if a hero is found
      if (hero) {
        // get all slide images
        const heroImages = hero.relationships.heroSlides.map((slide) => {
          const image = slide?.relationships?.primaryImage?.relationships?.image?.gatsbyImage || {};

          if (!isEmpty(image) && getBackgroundImageSrc(image, 500)) {
            return getBackgroundImageSrc(image, 500);
          }

          // if no image, return null (should be
          // one if admins enter one properly but here for fallback)
          return null;
        }).filter(((x) => !!x));

        // of the valid images, return the first one
        if (heroImages[0]) {
          return heroImages[0];
        }

        // return no image if there isn't one in the hero
        return null;
      }

      return null;
    };

    // use provided metaImage
    if (!isEmpty(pageImageSrc) && getBackgroundImageSrc(pageImageSrc, 500)) {
      return getBackgroundImageSrc(pageImageSrc, 500);
    }

    if (dynamic) {
      const dynamicMetaImage = dynamic?.relationships?.field_meta_image
        ?.relationships?.field_image?.gatsbyImage;

      // eslint-disable-next-line max-len
      if (getBackgroundImageSrc(dynamicMetaImage, 500) || !isEmpty(dynamic?.field_meta_image?.default)) {
        return getBackgroundImageSrc(dynamicMetaImage, 500)
          || dynamic?.field_meta_image?.default
          || null;
      }
    }

    if (pages) {
      // from the current level to any previous levels, check for a hero image
    // to use as the meta image
      if (levels.length > 0) {
        for (let i = levels.length; i !== 0; i -= 1) {
          const levelContent = Array.from(pages.values())[i - 1]?.relationships?.pageContent;
          const hero = getHeroImageFromContent(levelContent || []);
          if (hero) {
            return hero;
          }
        }
      }

      // if on homepage, check for a valid hero image to use as meta image
      if (pages.get(Level.LEVEL_0)) {
        const homepageContent = pages.get(Level.LEVEL_0)?.relationships?.pageContent;
        const hero = getHeroImageFromContent(homepageContent || []);
        if (hero) {
          return hero;
        }
      }
    }

    // if none of the above images are found, use fallback provided by default data
    if (defaultMetaImage && getBackgroundImageSrc(defaultMetaImage, 500)) {
      return getBackgroundImageSrc(defaultMetaImage, 500);
    }

    // if none of the above images are found, use static fallback
    if (metaImageFallback) return metaImageFallback;

    return '';
  }, [currentPage, pages, levels, defaultSeason, dynamic, metaImageFallback, defaultMetaImage]);

  // TODO: lift up logic for determining location into gatsby node?
  const validHotjarPage = () => (
    (gatsbyConfig?.validHotjarPages)
      ? gatsbyConfig?.validHotjarPages.some((x) => pathname.includes(x))
      : false
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      {/* Page Metadata */}
      <title>{textFilter(metaTitle)}</title>
      <link rel="canonical" href={metaUrl} />
      <meta name="description" content={textFilter(metaDescription)} />
      <meta name="robots" content={`${(metaNoIndex) ? 'noindex,' : ''}${(metaNoFollow) ? 'nofollow,' : ''}max-snippet:-1, max-image-preview:large, max-video-preview:-1`} />

      {/* Open Graph */}
      <meta property="og:title" content={textFilter(metaTitle)} />
      <meta property="og:description" content={textFilter(metaDescription)} />
      <meta property="og:url" content={metaOpengraphHref} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:image:secure_url" content={metaImage} />

      {/* Facebook Domain Verification */}
      <meta name="facebook-domain-verification" content={process.env.GATSBY_FB_VERIFICATION_KEY} />

      {/* Hotjar Tracking */}
      {((gatsbyConfig?.validHotjarPages
        && gatsbyConfig?.validHotjarPages?.length > 0
        && validHotjarPage()
        && hotjarID
        && hotjarSV
      )) && (
        <script>
          {`(function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${hotjarID},hjsv:${hotjarSV}};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`}
        </script>
      )}
    </Helmet>
  );
};

DynamicSEO.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  currentPage: PropTypes.shape().isRequired,
  levels: PropTypes.arrayOf(PropTypes.string).isRequired,
  pages: PropTypes.shape(),
  defaultSeason: PropTypes.string,
  dynamic: PropTypes.shape(),
};

DynamicSEO.defaultProps = {
  title: '',
  defaultSeason: null,
  pages: null,
  dynamic: null,
};
