/* eslint-disable import/no-dynamic-require */
import PropTypes from 'prop-types';
import React from 'react';

import { Components } from '@powdr/constants';
import {
  getBackgroundImageSrc, getOriginDomain, isEmpty, textFilter,
} from '@powdr/utils';
import { useStaticData } from '@powdr/web/src/hooks';

const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

export const SEO = ({
  currentPageData,
  location,
  parentPages,
}) => {
  const {
    metaImageFallback,
    defaultMeta: {
      metaImage: defaultMetaImage,
      metaDescription: defaultMetaDescription,
      metaTitle: defaultMetaTitle,
    },
    defaultSeason,
  } = useStaticData();
  const hotjarID = process.env.GATSBY_HOTJAR_ID || null;
  const hotjarSV = process.env.GATSBY_HOTJAR_SV || null;
  const metaNoFollow = currentPageData?.isNoFollow || false;
  const metaNoIndex = currentPageData?.isNoIndex || false;
  const metaTitle = currentPageData?.metaPageTitle || currentPageData?.title || defaultMetaTitle || '';
  const metaUrl = location?.pathname || '';
  const metaOpengraphHref = location?.pathname || '';
  const metaDescription = currentPageData?.metaPageDescription || currentPageData?.pageDescription || defaultMetaDescription || '';
  const metaImage = () => {
    if (!currentPageData && !defaultMetaImage && !metaImageFallback) return '';
    const { gatsbyImage: pageImageSrc } = currentPageData
      ?.relationships
      ?.metaPageImage
      ?.relationships
      ?.image || {};

    const getHeroImageFromContent = (content) => {
      const findComponent = (key) => content.filter(
        (component) => (
          component.typeName === key
          && (
            component?.componentSeason === defaultSeason
            || component?.componentSeason === null
          )
        ),
      ) || [];
      const hero = findComponent(Components.CONTENT_BLOCK_HERO)?.[0];

      // if a hero is found
      if (hero) {
        // get all slide images
        const heroImages = hero.relationships.heroSlides.map((slide) => {
          const image = slide?.relationships?.primaryImage?.relationships?.image?.gatsbyImage || {};

          if (!isEmpty(image) && getBackgroundImageSrc(image, 500)) {
            return getBackgroundImageSrc(image, 500);
          }

          // if no image, return null (should be
          // one if admins enter one properly but here for fallback)
          return null;
        }).filter(((x) => !!x));

        // of the valid images, return the first one
        if (heroImages[0]) {
          return heroImages[0];
        }

        // return no image if there isn't one in the hero
        return null;
      }

      return null;
    };

    // use provided metaImage
    if (!isEmpty(pageImageSrc) && getBackgroundImageSrc(pageImageSrc, 500)) {
      return getBackgroundImageSrc(pageImageSrc, 500);
    }

    if (parentPages) {
      // from the current level to any previous levels, check for a hero image
    // to use as the meta image
      if (parentPages.length > 0) {
        for (let i = parentPages.length; i !== 0; i -= 1) {
          const levelContent = parentPages[i - 1]?.relationships?.pageContent;
          const hero = getHeroImageFromContent(levelContent || []);
          if (hero) {
            return hero;
          }
        }
      }

      // if on homepage, check for a valid hero image to use as meta image
      if (location?.pathname === '/') {
        const homepageContent = parentPages[0]?.relationships?.pageContent;
        const hero = getHeroImageFromContent(homepageContent || []);
        if (hero) {
          return hero;
        }
      }
    }

    // if none of the above images are found, use fallback provided by default data
    if (defaultMetaImage && getBackgroundImageSrc(defaultMetaImage, 500)) {
      return getBackgroundImageSrc(defaultMetaImage, 500);
    }

    // if none of the above images are found, use static fallback
    if (metaImageFallback) return metaImageFallback;

    return '';
  };

  const validHotjarPage = () => (
    (gatsbyConfig?.validHotjarPages)
      ? gatsbyConfig?.validHotjarPages.some((x) => location?.pathname.includes(x))
      : false
  );

  return (
    <>
      {/* Page Metadata */}
      <title>{textFilter(metaTitle)}</title>
      <link rel="canonical" href={`${getOriginDomain()}${metaUrl}`} />
      <meta name="description" content={textFilter(metaDescription)} />
      <meta name="robots" content={`${(metaNoIndex) ? 'noindex,' : ''}${(metaNoFollow) ? 'nofollow,' : ''}max-snippet:-1, max-image-preview:large, max-video-preview:-1`} />

      {/* Open Graph */}
      <meta property="og:title" content={textFilter(metaTitle)} />
      <meta property="og:description" content={textFilter(metaDescription)} />
      <meta property="og:url" content={metaOpengraphHref} />
      <meta property="og:image" content={metaImage()} />
      <meta property="og:image:secure_url" content={metaImage()} />

      {/* Facebook Domain Verification */}
      <meta name="facebook-domain-verification" content={process.env.GATSBY_FB_VERIFICATION_KEY} />

      {/* Hotjar Tracking */}
      {((gatsbyConfig?.validHotjarPages
        && gatsbyConfig?.validHotjarPages?.length > 0
        && validHotjarPage()
        && hotjarID
        && hotjarSV
      )) && (
        <script>
          {`(function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${hotjarID},hjsv:${hotjarSV}};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`}
        </script>
      )}
    </>
  );
};

SEO.propTypes = {
  parentPages: PropTypes.arrayOf(),
  currentPageData: PropTypes.arrayOf(),
  location: PropTypes.string.isRequired,
};

SEO.defaultProps = {
  parentPages: null,
  currentPageData: null,
};
